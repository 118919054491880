<template>
  <q-page class="" padding>
    <on-demand-form
      :options="options"
      :location-query="placeSearch"
      :mapper="placeMapper"
      :user="user"
      :initial="initial"
      :labels="labels"
      :addresses="{saved, recent}"
      time
      no-return
      no-one-way-return-toggle
      type="fas fa-bus"
      @submit="onSubmit"
    />
  </q-page>
</template>

<script>
import date from 'utils/date-time'
import OnDemandForm from '../form/'
import { placeMapper } from '../form/mappers'
import { query as placeSearch } from 'api/places'
import { mapGetters } from 'vuex'
export default {
  components: {
    OnDemandForm
  },
  data () {
    return {
      labels: {
        location: {
          origin: this.$t('going_from'),
          destination: this.$t('going_to')
        },
        submit: this.$tc('find.bus')
      },
      initial: {
        departMin: date.newDate(),
        origin: {},
        destination: {},
        date: {
          outbound: date.addToDate(date.newDate(), { minutes: 5 }),
          inbound: null
        },
        options: {
        }
      },
      options: {
      },
      returning: false,
      locationResults: null,
      activeInput: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'userLookup',
      recent: 'addresses/recent',
      saved: 'addresses/saved'

    }),
    disabled () {
      if (!this.parameters.origin || !this.parameters.destination) {
        return true
      }
      return false
    },
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.initial.origin = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    placeSearch,
    placeMapper,
    onSubmit (values) {
      const mapped = {
        ...values,
        depart: values.date.outbound,
        return: values.date.inbound
      }
      this.$store.dispatch('ondemand/stash', { parameters: mapped })
      this.$router.push({ name: 'ondemand-bus-routes' })
    }
  }
}
</script>
